export const departureConstants = {
    //Crear
    DEPARTURE_CREATE_REQUEST: 'DEPARTURE_CREATE_REQUEST',
    DEPARTURE_CREATE_SUCCESS: 'DEPARTURE_CREATE_SUCCESS',
    DEPARTURE_CREATE_FAILURE: 'DEPARTURE_CREATE_FAILURE',

    DEPARTURE_GET_REQUEST: 'DEPARTURE_GET_REQUEST',
    DEPARTURE_GET_SUCCESS: 'DEPARTURE_GET_SUCCESS',
    DEPARTURE_GET_FAILURE: 'DEPARTURE_GET_FAILURE',

    DEPARTURE_TABLE_REQUEST: 'DEPARTURE_TABLE_REQUEST',
    DEPARTURE_TABLE_SUCCESS: 'DEPARTURE_TABLE_SUCCESS',
    DEPARTURE_TABLE_FAILURE: 'DEPARTURE_TABLE_FAILURE',

    DEPARTURE_SELECT_REQUEST: 'DEPARTURE_SELECT_REQUEST',
    DEPARTURE_SELECT_SUCCESS: 'DEPARTURE_SELECT_SUCCESS',
    DEPARTURE_SELECT_FAILURE: 'DEPARTURE_SELECT_FAILURE',

    DEPARTURE_DATA_REQUEST: 'DEPARTURE_DATA_REQUEST',
    DEPARTURE_DATA_SUCCESS: 'DEPARTURE_DATA_SUCCESS',
    DEPARTURE_DATA_FAILURE: 'DEPARTURE_DATA_FAILURE',

};
