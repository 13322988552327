export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    UPDATE_DATA_REQUEST: 'USERS_UPDATE_DATA_REQUEST',
    UPDATE_DATA_SUCCESS: 'USERS_UPDATE_DATA_SUCCESS',
    UPDATE_DATA_FAILURE: 'USERS_UPDATE_DATA_FAILURE',

    USER_TABLE_REQUEST: 'USER_TABLE_REQUEST',
    USER_TABLE_SUCCESS: 'USER_TABLE_SUCCESS',
    USER_TABLE_FAILURE: 'USER_TABLE_FAILURE',

    USER_GET_REQUEST: 'USER_GET_REQUEST',
    USER_GET_SUCCESS: 'USER_GET_SUCCESS',
    USER_GET_FAILURE: 'USER_GET_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_FAILURE: 'USER_LIST_FAILURE',
};
