export const offlineConstants = {
    //Crear
    SALES_OFFLINE_CREATE_REQUEST: 'SALES_OFFLINE_CREATE_REQUEST',
    SALES_OFFLINE_CREATE_SUCCESS: 'SALES_OFFLINE_CREATE_SUCCESS',
    SALES_OFFLINE_CREATE_FAILURE: 'SALES_OFFLINE_CREATE_FAILURE',

    SALES_OFFLINE_UPDATE_REQUEST: 'SALES_OFFLINE_UPDATE_REQUEST',
    SALES_OFFLINE_UPDATE_SUCCESS: 'SALES_OFFLINE_UPDATE_SUCCESS',
    SALES_OFFLINE_UPDATE_FAILURE: 'SALES_OFFLINE_UPDATE_FAILURE',

};
