//Tipos de salidas 
module.exports = {

    code: {
        banesco:1,
        provincial: 2,
        bicentenario: 3,
        bod: 4,
    },

    name: {
        1:'BANESCO',
        2:'PROVINCIAL',
        3:'BICENTENARIO',
        4:'BOD',
    }
}