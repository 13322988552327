export const offerConstants = {
    //Crear
    OFFER_CREATE_REQUEST: 'OFFER_CREATE_REQUEST',
    OFFER_CREATE_SUCCESS: 'OFFER_CREATE_SUCCESS',
    OFFER_CREATE_FAILURE: 'OFFER_CREATE_FAILURE',

    OFFER_TABLE_REQUEST: 'OFFER_TABLE_REQUEST',
    OFFER_TABLE_SUCCESS: 'OFFER_TABLE_SUCCESS',
    OFFER_TABLE_FAILURE: 'OFFER_TABLE_FAILURE',

    OFFER_DELETE_REQUEST: 'OFFER_DELETE_REQUEST',
    OFFER_DELETE_SUCCESS: 'OFFER_DELETE_SUCCESS',
    OFFER_DELETE_FAILURE: 'OFFER_DELETE_FAILURE',

};
