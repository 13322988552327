//Tipos de salidas 
module.exports = {

    cutOutCode: {
        //Recortes de jamón
        1:31,//'AREPERO',
        74:31,//'AREPERO MOHAN',
        2:31,//'ESPALDA',
        3:31,//'PIERNA',
        4:31,//'FIAMBRE',
        89:31,//'FIAMBRE MOHAN',
        5:31,//'PASTEL CUADRADO',
        6:31,//'PASTEL DE CARNE REDONDO',
        55:31,//mozzarella',
        60:31,//mozzarella'DISROCA,
        56:31,//'JAMON AHUMADO'
        80:31,//'BOLOGNA'

        //Recortes de ahumados
        10:32,//'TOCINETA AHUMADA',
        13:32,//'CHULETA AHUMADA',
        18:32,//'LOMO CANADIENSE',
        19:32,//'LOMO TIPO YORK',
        46:32,//'PERNIL AHUMADO'
        48:32,//'LOMITO AHUMADO'
        15:32,//'QUESO AMARILLO CAMPORICO'
        70:32,//'QUESO AMARILLO LA ABUELA'
        83:32,//'QUESO AMARILLO TREBOL'
        96:32,//'QUESO AMARILLO DEL VALLE'


    },

}