/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../actions';
// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import SideBar from "../../components/SideBar/SideBar"
import { Col, Row, Button, Form, FormGroup, Label, Container, Alert  } from 'reactstrap';
import { useForm, Controller  } from "react-hook-form";
import { history } from '../../helpers';
import NumberFormat from 'react-number-format';

function ProductCreatePage() {

  	useEffect(() => {
		document.body.classList.add("landing-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		return function cleanup() {
			document.body.classList.remove("landing-page");
			document.body.classList.remove("sidebar-collapse");
		};
    });
      
	//usuario
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    //Alertas
    const alert = useSelector(state => state.alert);
    //Mostrar alertas
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    
    useEffect(() => {
        if(alert.message){
            setVisible(true); 
            window.setTimeout(()=>{setVisible(false)},5000);   
        }
    },[alert]);

    //Form Data
    const { handleSubmit, register, errors, reset, control } = useForm();

    //Registrar data
    const onCreateData = (data, e) => {
        //precio sin las comas
        data.price = data.price.replace(/,/g, '');
        dispatch(productActions.createProduct( data, user ));
    };

    //State de guardado
    const registering = useSelector(state => state.products.registering);

    const statusRegister = useSelector(state => state.products);
    //Verificar si guardo y limpiar form
    useEffect(() => {
        if(statusRegister.success){
            reset({
                name:'',
                price:'',
                wholesalePrice:'',
                minWeight:''
            });
        }
    },[statusRegister.success]);

    return (
        <>
            <div className="d-flex" id="wrapper">
				<SideBar/>
				<div id="page-content-wrapper">
					<AdminNavbar/>
                    <div className="container-fluid">
                        <Container>
                        <Row>
                            <Col sm="10" md={{ size: 8, offset: 2 }}>
                                <h3 style={{ fontWeight:'bold',fontStyle: 'italic'}}>Añadir producto</h3>
                                {alert.message &&
                                    <Alert color={`alert ${alert.type}`} isOpen={visible} fade={true}>
                                        <div className="container">
                                            {alert.message}
                                            <button
                                                type="button"
                                                className="close"
                                                aria-label="Close"
                                                onClick={onDismiss}
                                            >
                                                <span aria-hidden="true">
                                                <i className="now-ui-icons ui-1_simple-remove"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </Alert>
                                }
                                <Form onSubmit={handleSubmit(onCreateData)} className="form">
                                    <Row form>
                                        <Col md={3}>  
                                            <FormGroup>
                                                <Label for="code">Cod. Producto</Label>
                                                <input
                                                  
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.code ? ' is-invalid' : '')}
                                                    name="code"
                                                    ref={register({
                                                        required: "Código de producto es requerido",
                                                        
                                                    })}
                                                />
                                                {errors.code && <div className="invalid-feedback">{errors.code.message}</div>}
                                            </FormGroup>
                                            </Col>
                                            
                                         <FormGroup>
                                                <Label for="name">Nombre</Label>
                                                <input
                                                   
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.name ? ' is-invalid' : '')}
                                                    name="name"
                                                    ref={register({
                                                        required: "El nombre es requerido",
                                                    })}
                                                />
                                                {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="serial">Serial</Label>
                                                <input
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.serial ? ' is-invalid' : '')}
                                                    name="serial"
                                                    ref={register({
                                                        required: "El serial es requerido",
                                                    })}
                                                />
                                                {errors.serial && <div className="invalid-feedback">{errors.serial.message}</div>}
                                            </FormGroup>

                                            
                                            <FormGroup>
                                                <Label for="brand">Marca</Label>
                                                <input
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.brand ? ' is-invalid' : '')}
                                                    name="brand"
                                                    ref={register({
                                                        required: "El marca es requerida",
                                                    })}
                                                />
                                                {errors.brand && <div className="invalid-feedback">{errors.brand.message}</div>}
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="accessory">Acompañante</Label>
                                                <input
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.accessory ? ' is-invalid' : '')}
                                                    name="accessory"
                                                    ref={register({})}
                                                />
                                                {errors.accessory && <div className="invalid-feedback">{errors.accessory.message}</div>}
                                            </FormGroup>

                                            
                                            <FormGroup>
                                                <Label for="observation">Observacion</Label>
                                                <input
                                                    autoComplete="off"
                                                    className={'form-control' + (errors.observation ? ' is-invalid' : '')}
                                                    name="observation"
                                                    ref={register({})}
                                                />
                                                {errors.observation && <div className="invalid-feedback">{errors.observation.message}</div>}
                                            </FormGroup>



                                        </Row>
                                        <Row form>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="presentation">Se descuenta en</Label>{' '}
                                                <select className={'form-control' + (errors.presentation ? ' is-invalid' : '')} name="presentation"
                                                    ref={register({ 
                                                            required: "La presentación es requerida" 
                                                        })}>
                                                        <option key="kg" name="kg" value="kg">kg</option>
                                                        <option key="Bultos" name="Bultos" value="Bultos">Bultos</option>
                                                        <option key="Bobinas" name="Bobinas" value="Bobinas">Bobinas</option>
                                                        <option key="Unidades" name="Unidades" value="Unidades">Unidades</option>
                                                </select>
                                                {errors.presentation && <div className="invalid-feedback d-block">{errors.presentation.message}</div>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="price">Precio ($)</Label>
                                                <Controller
                                                    name="price"
                                                    control={control}
                                                    rules={{
                                                       
                                                        required: "El precio es requerido",
                                                    }}
                                                    as={<NumberFormat  className={'form-control' + (errors.price ? ' is-invalid' : '')} thousandSeparator={true} />}
                                                />
                                                {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*  <Row form>
                                        <Col md={6}>
                                          <FormGroup>
                                                <Label for="wholesalePrice">Precio al mayor</Label>
                                                <Controller
                                                    omit="true"
                                                    name="wholesalePrice"
                                                    control={control}
                                                    rules={{
                                                        required: "El precio es requerido",
                                                    }}
                                                   
                                                    as={<NumberFormat className={'form-control' + (errors.wholesalePrice ? ' is-invalid' : '')} thousandSeparator={true} />}
                                                />
                                                {errors.wholesalePrice && <div className="invalid-feedback">{errors.wholesalePrice.message}</div>}
                                                </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="minWeight">Peso mínimo para venta al mayor</Label>
                                                <Controller
                                                    name="minWeight"
                                                    control={control}
                                                    rules={{
                                                       
                                                        required: "El peso es requerido",
                                                    }}
                                                    as={<NumberFormat className={'form-control' + (errors.minWeight ? ' is-invalid' : '')} thousandSeparator={true} />}
                                                />
                                                {errors.minWeight && <div className="invalid-feedback">{errors.minWeight.message}</div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>*/}
                                    
                                    <span className="form-controlp">
                                        <input type="checkbox" name="decrease" display="none" ref={register}></input>
                                            {" "}
                                      
                                            <span className="form-controlp"></span>
                                        </span>
                                    
                                   
                                        <span className="form-controlp">
                                        <input type="checkbox" name="reweigh" ref={register}></input>
                                            {" "}
                                        
                                            <span className="form-controlp"></span>
                                        </span>
                                     
                                   
                                    
                                        <span className="form-controlp">
                                        <input type="checkbox" name="mincemeat" ref={register}></input>
                                            {" "}
                                        
                                            <span className="form-controlp"></span>
                                        </span>
                                     
                                 
                                    <div className="d-flex justify-content-between">
                                        <Button color="primary" disabled={registering}>
                                            {registering && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Guardar
                                        </Button>
                                        <Button onClick={e =>{e.preventDefault(); history.goBack();} }>Cancelar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        </Container>
                    </div>

				</div>
            </div>
        </>
    );
}

export default ProductCreatePage;