export const agencyConstants = {
    //Crear
    AGENCY_CREATE_REQUEST: 'AGENCY_CREATE_REQUEST',
    AGENCY_CREATE_SUCCESS: 'AGENCY_CREATE_SUCCESS',
    AGENCY_CREATE_FAILURE: 'AGENCY_CREATE_FAILURE',

    AGENCY_GET_REQUEST: 'AGENCY_GET_REQUEST',
    AGENCY_GET_SUCCESS: 'AGENCY_GET_SUCCESS',
    AGENCY_GET_FAILURE: 'AGENCY_GET_FAILURE',

    AGENCY_UPDATE_REQUEST: 'AGENCY_UPDATE_REQUEST',
    AGENCY_UPDATE_SUCCESS: 'AGENCY_UPDATE_SUCCESS',
    AGENCY_UPDATE_FAILURE: 'AGENCY_UPDATE_FAILURE',

    AGENCY_TABLE_REQUEST: 'AGENCY_TABLE_REQUEST',
    AGENCY_TABLE_SUCCESS: 'AGENCY_TABLE_SUCCESS',
    AGENCY_TABLE_FAILURE: 'AGENCY_TABLE_FAILURE',

    AGENCY_SELECT_REQUEST: 'AGENCY_SELECT_REQUEST',
    AGENCY_SELECT_SUCCESS: 'AGENCY_SELECT_SUCCESS',
    AGENCY_SELECT_FAILURE: 'AGENCY_SELECT_FAILURE',

};
