export const coinConstants = {
    //Crear
    COIN_CREATE_REQUEST: 'COIN_CREATE_REQUEST',
    COIN_CREATE_SUCCESS: 'COIN_CREATE_SUCCESS',
    COIN_CREATE_FAILURE: 'COIN_CREATE_FAILURE',

    COIN_GET_REQUEST: 'COIN_GET_REQUEST',
    COIN_GET_SUCCESS: 'COIN_GET_SUCCESS',
    COIN_GET_FAILURE: 'COIN_GET_FAILURE',

    COIN_UPDATE_REQUEST: 'COIN_UPDATE_REQUEST',
    COIN_UPDATE_SUCCESS: 'COIN_UPDATE_SUCCESS',
    COIN_UPDATE_FAILURE: 'COIN_UPDATE_FAILURE',

    COIN_TABLE_REQUEST: 'COIN_TABLE_REQUEST',
    COIN_TABLE_SUCCESS: 'COIN_TABLE_SUCCESS',
    COIN_TABLE_FAILURE: 'COIN_TABLE_FAILURE',

    COIN_SELECT_REQUEST: 'COIN_SELECT_REQUEST',
    COIN_SELECT_SUCCESS: 'COIN_SELECT_SUCCESS',
    COIN_SELECT_FAILURE: 'COIN_SELECT_FAILURE',

};
