export const ticketConstants = {
    //Crear
    TICKET_CREATE_REQUEST: 'TICKET_CREATE_REQUEST',
    TICKET_CREATE_SUCCESS: 'TICKET_CREATE_SUCCESS',
    TICKET_CREATE_FAILURE: 'TICKET_CREATE_FAILURE',

    TICKET_TABLE_REQUEST: 'TICKET_TABLE_REQUEST',
    TICKET_TABLE_SUCCESS: 'TICKET_TABLE_SUCCESS',
    TICKET_TABLE_FAILURE: 'TICKET_TABLE_FAILURE',

    TICKET_UPDATE_REQUEST: 'TICKET_UPDATE_REQUEST',
    TICKET_UPDATE_SUCCESS: 'TICKET_UPDATE_SUCCESS',
    TICKET_UPDATE_FAILURE: 'TICKET_UPDATE_FAILURE',

    TICKET_DELETE_REQUEST: 'TICKET_DELETE_REQUEST',
    TICKET_DELETE_SUCCESS: 'TICKET_DELETE_SUCCESS',
    TICKET_DELETE_FAILURE: 'TICKET_DELETE_FAILURE',
};
