/* eslint-disable */
import { pendingPaymentsConstants } from '../constants';
import { pendingPaymentsService } from '../services';
import { alertActions } from '.';

export const pendingPaymentsActions = {

    //Ventas generales
    dataTable(user, pageIndex, pageSize, sortBy, filters) {
        return dispatch => {
            dispatch(request());

            pendingPaymentsService.pendingPaymentsTable(user, pageIndex, pageSize, sortBy, filters)
                .then(
                    pendingPayments => {
                        dispatch(success(pendingPayments))
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };

        function request() { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_TABLE_REQUEST } }
        function success(pendingPayments) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_TABLE_SUCCESS, pendingPayments } }
        function failure(error) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_TABLE_FAILURE, error } }
    },
   
    //Registrar pending payment
    pendingPaymentsCreate(pendingPayment) {
        return dispatch => {
            dispatch(request(pendingPayment));

            console.log(pendingPayment)

            pendingPaymentsService.pendingPaymentsCreate(pendingPayment)
                .then(
                    pendingPayments => { 
                        dispatch(success(pendingPayments));
                        dispatch(alertActions.success('¡Se ha registrado el crédito correctamente!'));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };

        function request(pendingPayments) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_CREATE_REQUEST, pendingPayments } }
        function success(pendingPayments) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_CREATE_SUCCESS, pendingPayments } }
        function failure(error) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_CREATE_FAILURE, error } }
    },

    //editar pending payment 
    pendingPaymentsUpdate(id, data) {
        return dispatch => {
            dispatch(request(id));
    
            pendingPaymentsService.pendingPaymentsUpdate(id,data)
                .then(
                    () => {
                        dispatch(success());
                        dispatch(alertActions.success('¡Se ha registrado el pago correctamente!'));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };
    
        function request(id) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_UPDATE_REQUEST, id } }
        function success() { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_UPDATE_SUCCESS } }
        function failure(error) { return { type: pendingPaymentsConstants.PENDING_PAYMENTS_UPDATE_FAILURE, error } }
    },

};
