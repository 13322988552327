/**
 * Pesos calculados de bolsa para determinados productos por código
 */
export const WeightProduct = [
    //COMBOS ESPECIALES********* 

     //COMBO COD 44 PAN DE JAMON ****
     {
        code: 44, weight:0.750 
    },

     //COMBO COD 63 FIAMBRE Y MOZZARELLA ****
     {
        code: 63, weight:0.500 
    },

     //COMBO COD 94 AREPERO Y MOZZARELLA ****
     {
        code: 94, weight:0.500 
    },

     //COMBO COD 95 PIZZERO ****
     {
        code: 95, weight:0.650 
    },
    

     //COMBO COD 96 HALLQUERO ****
     {
        code: 96, weight:0.600 
    },
    
    //CHICHARRON
     {
        code: 33, weight:0.080
    },
    //MORATADELA DE 450GR ******
    {
        code: 69, weight:0.450 
    },
    //MORTADELA 900GR J KING  *****
    {
        code: 84, weight:0.900 
    },
    //MANTECA
    {
        code: 34, weight:0.680
    },

      //YOGURT
    {
        code: 77, weight:0.520
    },

       //QUESO DE MANO
    {
        code: 8, weight:0.640
    },

         //QUESO GUAYANES
    {
        code: 39, weight:0.640
    },

         //CREMA DE LECHE
         {
            code: 21, weight:0.200
        }
];